import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupplierProperty, SupplierSchema } from '../types/SupplierSchema';
import { fetchSupplierGeneralInfo } from '../services/fetchSupplierGeneralInfo';
import { fetchSupplierAccountManagerInfo } from '../services/fetchSupplierAccountManagerInfo';
import { updateSupplierAccountManagerInfo } from '../services/updateSupplierAccountManagerInfo';
import { fetchSupplierProperties } from '../services/fetchSupplierProperties';

const initialState: SupplierSchema = {
    dataIsLoading: false,
    supplierGeneralInfo: null,
    supplierAccountManagerInfo: null,
    supplierProperties: null,
    isLoadingSupplierProperties: false,
    selectedSupplierPropertyId: null,
};

export const SupplierSlice = createSlice({
    name: 'Supplier',
    initialState,
    reducers: {
        setSelectedSupplierPropertyId: (
            state,
            action: PayloadAction<string>,
        ) => {
            const property =
                state.supplierProperties !== null &&
                state.supplierProperties?.filter(
                    (property: SupplierProperty) =>
                        property.id === action.payload,
                );
            state.selectedSupplierPropertyId =
                property[0]?.id || state.supplierProperties[0]?.id;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSupplierGeneralInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(fetchSupplierGeneralInfo.fulfilled, (state, action) => {
                state.supplierGeneralInfo = action.payload;
                state.dataIsLoading = false;
            })
            .addCase(fetchSupplierGeneralInfo.rejected, (state, action) => {
                state.dataIsLoading = false;
            })
            .addCase(fetchSupplierAccountManagerInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(
                fetchSupplierAccountManagerInfo.fulfilled,
                (state, action) => {
                    state.supplierAccountManagerInfo =
                        action?.payload?.accountManager;
                    state.dataIsLoading = false;
                },
            )
            .addCase(
                fetchSupplierAccountManagerInfo.rejected,
                (state, action) => {
                    state.dataIsLoading = false;
                },
            )
            .addCase(updateSupplierAccountManagerInfo.pending, (state) => {
                state.dataIsLoading = true;
            })
            .addCase(
                updateSupplierAccountManagerInfo.fulfilled,
                (state, action) => {
                    state.supplierAccountManagerInfo =
                        action?.payload?.accountManager;
                    state.dataIsLoading = false;
                },
            )
            .addCase(
                updateSupplierAccountManagerInfo.rejected,
                (state, action) => {
                    state.dataIsLoading = false;
                },
            )
            .addCase(fetchSupplierProperties.pending, (state) => {
                state.isLoadingSupplierProperties = true;
            })
            .addCase(fetchSupplierProperties.fulfilled, (state, action) => {
                state.supplierProperties = action?.payload?.data;
                state.isLoadingSupplierProperties = false;
            })
            .addCase(fetchSupplierProperties.rejected, (state, action) => {
                state.isLoadingSupplierProperties = false;
            });
    },
});

export const { actions: supplierActions } = SupplierSlice;
export const { reducer: supplierReducer } = SupplierSlice;
